import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import history from '../../../history'
import { Map } from 'immutable'

import StyledEditGame from './game.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'
import Theme from '../../ui/theme'

import {  getProfile } from '../../../state/selectors/profile'
import {  getGame } from '../../../state/selectors/game'

import { createGame, updateGame, readGame, resetGame } from '../../../state/actions/game'

import Tablet from '../../ui/tablet'
import Player from '../../ui/player'
import EditLevel from './level'

import queryString from 'query-string'
import { _ } from 'core-js';


export class EditGame extends Component {
  
  constructor(props) {
    super()

    // reset game
    props.fetchResetGame();

    const values = queryString.parse(window.location.search);
    var gameId = "";
    if (values.gameId != null && values.gameId != "") {
      gameId = values.gameId;
    } else if (values.gameid != null && values.gameid != "") {
      gameId = values.gameid;
    } else if (values.id != null && values.id != "") {
      gameId = values.id;
    }
    if (gameId != "") {
      this.state = {
        isUpdate: true,
        isChanged: false,
        newLevels: [],
        gameId: gameId,
        theme: ""
      }
      // fetch game data
      props.fetchReadGame(gameId);
    } else {
      this.state = {
        isUpdate: false,
        isChanged: false,
        newLevels: [],
        gameId: gameId,
        theme: ""
      }
    }

    this.clearChanges = this.clearChanges.bind(this);
    this.deleteNewLevel = this.deleteNewLevel.bind(this);
    this.addNewLevel = this.addNewLevel.bind(this);
    this.updateLevel = this.updateLevel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitGameDetails = this.submitGameDetails.bind(this);
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    
   this.setState({
      [ id ]: value,
      isChanged: true
    });
  }
  
  clearChanges = () => {
    this.setState(state => ({
      isChanged: false
    }));
  }

  submitGameDetails = e => {
    e.preventDefault()    
    const { profile, game } = this.props
    let gameAuthor = this.state.author;
    if (!gameAuthor) {
      gameAuthor = (game.getIn(['Game', 'Author'], null) == null) ? "" : game.getIn(['Game', 'Author'], null);
      if (!gameAuthor) {
        gameAuthor = profile.getIn(['Profile', 'FirstName'], null) + " " + profile.getIn(['Profile', 'SecondName'], null);
      }
    }
    let gameOrganization = this.state.organization;
    if (!gameOrganization) {
      gameOrganization = (game.getIn(['Game', 'Organization'], null) == null) ? "" : game.getIn(['Game', 'Organization'], null);
      if (!gameOrganization) {
        gameOrganization = profile.getIn(['Profile', 'Organization'], null);
      }
    }    
    if (!this.state.isUpdate) {      
      const profileId = profile.getIn(['Profile', 'Id'], null);
      let theme = this.state.theme;
      if (!theme) {
        theme = "0";
      }
      this.props.fetchCreateGame(profileId, this.state.title, this.state.description, this.state.subject, this.state.grade, this.state.complexity, this.state.individualInstruction, this.state.groupInstruction, gameAuthor, gameOrganization, this.state.access, theme, this.state.introduction);
    } else {
      const { game } = this.props
      const gameId = game.getIn(['Game', 'Id'], null);
      this.props.fetchUpdateGame(gameId, this.state.title, this.state.description, this.state.subject, this.state.grade, this.state.complexity, this.state.individualInstruction, this.state.groupInstruction, gameAuthor, gameOrganization, this.state.access, this.state.theme, this.state.introduction);
    }
    this.setState({
      isChanged: false,
      isUpdate: true,
    })
  }

  deleteNewLevel(id, sequence) {
    var array = [...this.state.newLevels]; // make a separate copy of the array
    array.splice(sequence, 1);
    this.setState({newLevels: array, updateLevel: true});
  }

  addNewLevel = (e) => {
    this.setState((prevState) => ({
      newLevels: [...prevState.newLevels, {}],
    }));
  }

  updateLevel() {
    this.setState({updateLevel: true});
  }

  goBack = () => {
    history.go(-1);
  }

  render() {
      const { game, profile } = this.props;
      const gameId = game.getIn(['Game', 'Id'], null);
      let resetingGame = this.state.gameId != "" && this.state.gameId != gameId;
      let { newLevels } = this.state;
      const lang = (game.getIn(['Game', 'Lang'], null) == null) ? "" : game.getIn(['Game', 'Lang'], null);
      if (this.state.lang == "") {
        this.state.lang = lang;
      }
      const title = (game.getIn(['Game', 'Title'], null) == null) ? "" : game.getIn(['Game', 'Title'], null);
      const description = (game.getIn(['Game', 'GameDescription'], null) == null) ? "" : game.getIn(['Game', 'GameDescription'], null);
      const subject = (game.getIn(['Game', 'Subject'], null) == null) ? "" : game.getIn(['Game', 'Subject'], null);
      const grade = (game.getIn(['Game', 'Grade'], null) == null) ? "" : game.getIn(['Game', 'Grade'], null);
      const complexity = (game.getIn(['Game', 'Complexity'], null) == null) ? "" : game.getIn(['Game', 'Complexity'], null);
      const individualInstruction = (game.getIn(['Game', 'IndividualInstruction'], null) == null) ? "" : game.getIn(['Game', 'IndividualInstruction'], null);
      const groupInstruction = (game.getIn(['Game', 'GroupInstruction'], null) == null) ? "" : game.getIn(['Game', 'GroupInstruction'], null);
      const introduction = (game.getIn(['Game', 'Introduction'], null) == null) ? "" : game.getIn(['Game', 'Introduction'], null);
      const access = (game.getIn(['Game', 'Access'], null) == null) ? "" : game.getIn(['Game', 'Access'], null);
      let author = (game.getIn(['Game', 'Author'], null) == null) ? "" : game.getIn(['Game', 'Author'], null);
      if (!author) {
        author = profile.getIn(['Profile', 'FirstName'], null) + " " + profile.getIn(['Profile', 'SecondName'], null);
      }
      let organization = (game.getIn(['Game', 'Organization'], null) == null) ? "" : game.getIn(['Game', 'Organization'], null);
      if (!organization) {
        organization = profile.getIn(['Profile', 'Organization'], null);
      }
      let theme = (game.getIn(['Game', 'Theme'], null) == null) ? "0" : game.getIn(['Game', 'Theme'], null) + "";
      if (theme != "0" && this.state.theme == "") {
        this.state.theme = theme;
      }
      const gameProfileId = (game.getIn(['Game', 'Profile', 'Id'], null) == null) ? null : game.getIn(['Game', 'Profile', 'Id'], null);
      let levels = (game.getIn(['Game', 'Levels', 'Level'], null) == null) ? "" : game.getIn(['Game', 'Levels', 'Level'], null);
      if (!levels) {
        levels = new Map();
      }
      const profileId = profile.getIn(['Profile', 'Id'], null);
      const canPublish = profile.getIn(['Profile', 'Email'], false) == "stavros.stavru@caringers.com";
      let notOwned = (this.state.isUpdate && profileId != gameProfileId);
      let unity = "";
      if (this.state.isUpdate && !this.state.isChanged) {
        unity = <Tablet><Player isHub gameId={gameId} date={new Date()} /></Tablet>
      }
      if (this.state.updateLevel) {
        unity = ""
        this.state.updateLevel = false;
      }
      return (
        <StyledEditGame>
         {resetingGame &&
          <div class="resetinggame">
            Играта се зарежда в момента. Моля, изчакайте.
          </div>
         }
         {!resetingGame &&
         <div id="editgame">
          <div class="details">
            <form onSubmit={this.submitGameDetails}>
              <h2>Моля въведете детайли на играта:</h2>
              <div class="inputs">
                <div class="row">
                  <input {...notOwned ? {disabled:"true"} : {}} id='title' {...(!this.state.isChanged ? {value: title} : {})} required placeholder="Заглавие*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <textarea {...notOwned ? {disabled:"true"} : {}}  id='description' {...(!this.state.isChanged ? {value: description} : {})} required placeholder="Кратко описание*" onChange={this.handleInputChange}></textarea>
                </div>
                <div class="row">
                  <input {...notOwned ? {disabled:"true"} : {}}  id='subject' {...(!this.state.isChanged ? {value: subject} : {})} required placeholder={process.theme.subject + "*"} onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: grade} : {})} required id='grade' onChange={this.handleInputChange}>
                      <option value="" disabled selected>Изберете категория*</option>
                      <option value="3. група">Игри за 3. предучилищна група</option>
                      <option value="4. група">Игри за 4. предучилищна група</option>
                      <option value="1. клас">Игри за 1. клас</option>
                      <option value="2. клас">Игри за 2. клас</option>
                      <option value="3. клас">Игри за 3. клас</option>
                      <option value="4. клас">Игри за 4. клас</option>
                      <option value="Бъди умен, мисли кръгово">Игри от проекта „Бъди умен, мисли кръгово“</option>
                      <option value="Да играем за Зеления пояс">Игри от турнето „Да играем за Зеления пояс“</option>
                  </select>
                </div>
                <div class="row">
                    <textarea {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: individualInstruction} : {})} id='individualInstruction' placeholder="Инструкции за индивидуалните задачи" onChange={this.handleInputChange}></textarea>
                </div>
                <div class="row">
                    <textarea {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: groupInstruction} : {})} id='groupInstruction' placeholder="Инструкции за груповите задачи" onChange={this.handleInputChange}></textarea>
                </div>
                <div class="row">
                  <textarea rows="7" {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: introduction} : {})} id='introduction' placeholder="Въведение в началото на играта" onChange={this.handleInputChange}></textarea>
                </div>
                <div class="row">
                  <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: complexity} : {})} id='complexity' required onChange={this.handleInputChange}>
                    <option value="" disabled selected>Изберете трудност*</option>
                    <option value="1">Много лесно</option>
                    <option value="2">Лесно</option>
                    <option value="3">Трудно</option>
                    <option value="4">Много трудно</option>
                  </select>
                </div>
                <div class="row">
                  <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: theme} : {})} id='theme' required onChange={this.handleInputChange}>
                    <option value="" disabled>Изберете дали да има задължителна тема</option>
                    <option value="0" selected>Темата ще се избира при генериране на кода за игра</option>
                    {process.theme.themes}
                  </select>
                </div>
                {this.state.theme && this.state.theme != "" && this.state.theme != "0" && this.state.theme.length > 0 &&
                  <div class="row">
                    <Theme id={this.state.theme} />
                  </div>
                }
                <div class="row">
                  <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: access} : {})} id='access' required onChange={this.handleInputChange}>
                    <option value="" disabled selected>Изберете достъп до играта*</option>
                    <option value="1">Достъп само чрез моя акаунт</option>
                    <option value="2">Достъп с код на играта</option>
                    {canPublish &&
                      <option value="3">Свободен достъп</option>
                    }
                  </select>
                </div>   
                <div class="row">
                  <input {...notOwned ? {disabled:"true"} : {}} id='author' {...(!this.state.isChanged ? {value: author} : {})} required placeholder="Автор*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input {...notOwned ? {disabled:"true"} : {}} id='organization' {...(!this.state.isChanged ? {value: organization} : {})} required placeholder="Организация*" onChange={this.handleInputChange}/>
                </div>
              </div>
              <div class='required'>* задължително поле</div>
              <div class='buttons'>
                  <div class="btn_edit">
                    <FixedButton>
                      <StandardButton disabled={notOwned || (this.state.isUpdate && !this.state.isChanged)} isSubmit text={(!this.state.isUpdate) ? "Създайте" : "Актуализирайте"} width="100%"/>
                    </FixedButton>
                  </div>
                  {this.state.isUpdate &&
                    <div class="btn_cancel">
                      <FixedButton>
                        <StandardButton disabled={!this.state.isChanged} click={this.clearChanges} text="Анулирайте промените" width="100%" type="cancel"/>
                      </FixedButton>
                    </div>
                  }
                  {this.state.isUpdate &&
                    <div class="btn_delete">
                      <FixedButton>
                        <StandardButton disabled={notOwned} href={'/delete?gameId=' + gameId}  text="Изтрийте" width="100%" type="delete"/>
                      </FixedButton>
                    </div>
                  }            
                 <div class="btn_back">
                    <FixedButton>
                      <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                    </FixedButton>
                  </div>
              </div>
            </form>
          </div>

          {this.state.isUpdate &&
            <div class="levels">
              <div class="levelstitle"><h2>Нива на играта:</h2></div>
              {
                levels.sort((a, b) => a.getIn(['SequenceNumber'], null) - b.getIn(['SequenceNumber'], null)).map((val, idx)=> {
                  return (
                    <EditLevel updateLevel={this.updateLevel} notOwned={notOwned} gameId={gameId} level={val} id={idx} sequence={idx} />
                  )
                })
              }
              {
                newLevels.map((val, idx)=> {
                  return (
                    <EditLevel updateLevel={this.updateLevel} gameId={gameId} id={idx + levels.size} sequence={idx} deleteNewLevel={this.deleteNewLevel} />
                  )
                })
              }
              <div class="addlevel">
                {this.state.isUpdate &&
                <FixedButton>
                  <StandardButton disabled={notOwned} click={this.addNewLevel} text="Добавете ново ниво" width="100%"/>
                </FixedButton>
                }
              </div>
              {unity != "" && levels.size > 0 &&
                <div id="player">
                  {unity}
                </div>
              }
            </div>
          }
          </div>
        }
      </StyledEditGame>
    )
  }
}

EditGame.propTypes = {
  game: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    game: getGame(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchCreateGame: (profileId, title, description, subject, grade, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction) => dispatch(createGame(profileId, title, description, subject, grade, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction)),
    fetchReadGame: (gameId) => dispatch(readGame(gameId)),
    fetchUpdateGame: (gameId, title, description, subject, grade, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction) => dispatch(updateGame(gameId, title, description, subject, grade, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction)),
    fetchResetGame: () => dispatch(resetGame()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGame)
